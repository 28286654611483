.App {
  width: auto;
  text-align: center;
  background-color: #282c34;
  color: aliceblue;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: gold;
  text-shadow: 2px 2px 5px rgba(39, 39, 216, 0.9);
}

.App-logo {
	height: 10vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.App-link {
  color: #61dafb;
}

.Footer {
  color: aliceblue;
}

.MoviePage {
  display: grid;
  justify-content: center;
}

.MovieList {
  
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
  padding: 0px;
}

.SinglePhoto {
  height: 700px;
  width: 400px;
}

.Photo {
  height: 350px;
  width: 200px;
}

.HomeCards {
  height: 365px;
  padding: 2.5px;
  margin: 5px;
  gap: 5px;
}
.HomeCards:hover {
  transform: scale(1.05);
}

.CardBody {
  background-color: #282c34;
  height: 150px;
  padding-bottom: 5px;
  color: aliceblue;
  font-size: medium;
}
.MovieScreen {
  width: 55rem;
  padding: 0px;
  margin: 0;
  gap: 0;
  color: white;
  }

.CardLinks {
  text-align: center;
  font-size: medium;
}

@media screen and (max-width: 600px) {
  .MovieList {
    grid-template-columns: 1fr 1fr;
  }
}

.search {
  border: none;
  outline: none;
  padding: 5px;
  width: 250px;
  line-height: 20px;
  margin-bottom: 5px;
  background-color:lightgrey;
}